import { ReactTyped } from 'react-typed';
import { Reveal } from './Reveal';
import {
	Button,
	HeroDetail,
	HeroSubTitle,
	// HeroTitle,
	HeroWrapper,
	Section,
} from './styled/style';

interface IHero {
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Hero = ({ setIsModalOpen }: IHero) => {
	return (
		<Section withMargin id="hero">
			<HeroWrapper>
				{/* <Reveal>
					<HeroTitle>
						Hey, we're Code Commit<span>.</span>
					</HeroTitle>
				</Reveal> */}
				<Reveal>
					<HeroSubTitle>
						We build{' '}
						<span>
							<ReactTyped
								strings={[
									'Website',
									'Portfolio',
									'Workflow',
									'Business Email',
									'Software',
								]}
								typeSpeed={120}
								backSpeed={140}
								loop
							/>
						</span>
					</HeroSubTitle>
				</Reveal>
				<Reveal>
					<HeroDetail>
						Our team have 10+ years of industry experience building complex
						softwares which are live in production.<br/>We also mentor/teach people
						how to program (incase you are intreseted to learn).<br/>Let's
						connect!
					</HeroDetail>
				</Reveal>
				<Reveal>
					<Button onClick={() => setIsModalOpen(true)}>Contact Us</Button>
				</Reveal>
			</HeroWrapper>
		</Section>
	);
};

export default Hero;
