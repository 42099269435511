import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { variant } from '../utils/constant';

interface IReveal {
	children: React.ReactNode;
	slide?: 'leftToRightSlide' | 'rightToLeftSlide';
	showGreenAnimation?: boolean;
	width?: 'fit-content' | '100%';
	main?:
		| 'downToUpMain'
		| 'leftToRightMain'
		| 'rightToLeftMain'
		| 'upToDownMain';
	delay?: number;
}

export const Reveal = ({
	children,
	slide = 'leftToRightSlide',
	showGreenAnimation = true,
	width = 'fit-content',
	main = 'downToUpMain',
	delay = 0.2,
}: IReveal) => {
	const { ref: inViewRef, inView } = useInView({ triggerOnce: true });
	const mainControl = useAnimation();
	const slideControl = useAnimation();

	useEffect(() => {
		if (inView) {
			console.log('Element is in view, starting animations.');
			mainControl.start('visible');
			slideControl.start('visible');
		} else {
			console.log('Element is not in view.');
		}
	}, [inView, mainControl, slideControl]);

	const slideVariant = variant[slide];

	const mainVariant = variant[main];

	return (
		<div
			ref={inViewRef}
			style={{ position: 'relative', width, overflow: 'hidden' }}
		>
			<motion.div
				variants={mainVariant}
				transition={{
					type: 'just',
					duration: 0.5,
					delay,
				}}
				initial="hidden"
				animate={mainControl}
			>
				{children}
			</motion.div>
			{showGreenAnimation && (
				<motion.div
					variants={slideVariant}
					initial={showGreenAnimation}
					animate={slideControl}
					transition={{ duration: 0.5, ease: 'easeIn' }}
					style={{
						position: 'absolute',
						top: 4,
						bottom: 4,
						left: 0,
						right: 0,
						background: 'var(--brand)',
						zIndex: 20,
					}}
				/>
			)}
		</div>
	);
};
