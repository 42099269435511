import { projects } from '../utils/constant';
import { Reveal } from './Reveal';
import {
	Header,
	HeaderLine,
	HeaderTitle,
	ServiceTech,
	ServiceDescription,
	ServiceDetails,
	ServiceTitle,
	ServiceWrapper,
	Section,
	ServiceImage,
} from './styled/style';
import service from '../assets/services';

const Service = () => {
	const getImage = (id: string) => {
		switch (id) {
			case 'solution':
				return service.solution;
			case 'seo':
				return service.seo;
			case 'website':
				return service.website;
			case 'mentor':
				return service.mentor;
			case 'email':
				return service.email;
		}
	};

	return (
		<Section id="service">
			<Header>
				<h3>
					<div
						style={{
							position: 'relative',
							width: 'fit-content',
							overflow: 'hidden',
						}}
					>
						<Reveal main="rightToLeftMain" slide="rightToLeftSlide">
							<HeaderTitle>
								Services<span>.</span>
							</HeaderTitle>
						</Reveal>
					</div>
				</h3>
				<HeaderLine></HeaderLine>
			</Header>
			<ServiceWrapper>
				{projects.map((project) => (
					<div style={{ opacity: 1, transform: 'none' }}>
						<Reveal width="100%" showGreenAnimation={false}>
							<ServiceImage>
								<img src={getImage(project.id)} alt={`${project.id}`} />
							</ServiceImage>
						</Reveal>
						<ServiceDetails>
							<div
								style={{
									position: 'relative',
									width: '100%',
									overflow: 'hidden',
								}}
							>
								<Reveal width="100%">
									<ServiceTitle>
										<h4>{project.name}</h4>
										<HeaderLine></HeaderLine>
									</ServiceTitle>
								</Reveal>
							</div>
							<div
								style={{
									position: 'relative',
									width: '100%',
									overflow: 'hidden',
								}}
							>
								{project.techStack && (
									<Reveal>
										<ServiceTech>{project.techStack}</ServiceTech>
									</Reveal>
								)}
							</div>
							<div
								style={{
									position: 'relative',
									width: 'fit-content',
									overflow: 'hidden',
								}}
							>
								<Reveal>
									<ServiceDescription>
										{project.decription}
										<span>Learn more &gt;</span>
									</ServiceDescription>
								</Reveal>
							</div>
						</ServiceDetails>
					</div>
				))}
			</ServiceWrapper>
		</Section>
	);
};

export default Service;
