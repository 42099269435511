import { motion } from 'framer-motion';
import stacks from '../assets/stack';

const LogoCarousel = () => {
	return (
		<div className="relative w-full h-full overflow-hidden ">
			<motion.div
				className="flex space-x-10 absolute" // Ensure the width is 200% to accommodate the duplicated logos
				initial={{ x: '10%' }}
				animate={{ x: '-100%' }}
				transition={{
					repeat: Infinity,
					duration: 70, // Increase duration to slow down the animation
					ease: 'linear',
				}}
			>
				{stacks.map((logo, index) => (
					<div key={index} className="flex-shrink-0 w-32 h-32 p-3">
						<img
							src={logo}
							alt={`Logo ${index + 1}`}
							className="w-full h-full object-contain"
						/>
					</div>
				))}
				{stacks.map((logo, index) => (
					<div key={index} className="flex-shrink-0 w-32 h-32 p-3">
						<img
							src={logo}
							alt={`Logo ${index + 1}`}
							className="w-full h-full object-contain"
						/>
					</div>
				))}
			</motion.div>
		</div>
	);
};

export default LogoCarousel;
