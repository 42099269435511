import android from './android.svg';
import angular from './angular.svg';
import appleStore from './apple-app-store.svg';
import css from './css-3.svg';
import docker from './docker.svg';
import firebase from './firebase.svg';
import html from './html-5.svg';
import javascript from './javascript.svg';
import json from './json-schema.svg';
import mongodb from './mongodb.svg';
import mysql from './mysql.svg';
import nodejs from './nodejs.svg';
import postman from './postman.svg';
import react from './react.svg';
import tailwindcss from './tailwindcss-icon.svg';
import typescript from './typescript-icon.svg';
import dotnet from './dotnet.svg';
const images = [
	android,
	angular,
	appleStore,
	css,
	docker,
	firebase,
	html,
	javascript,
	json,
	mongodb,
	mysql,
	nodejs,
	postman,
	react,
	tailwindcss,
	typescript,
	dotnet,
];
export default images;
