import { FaRegTimesCircle } from 'react-icons/fa';
import {
	ImageWrapper,
	ModalCard,
	ModalCloseButton,
	ModalContent,
	ModalInput,
	ModalTextArea,
	ModalWrapper,
	OutlineButton,
	ServiceWrapper,
} from './styled/style';
import contact from '../assets/contact.svg';
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';
import Lottie from 'react-lottie-player';
import check from '../assets/jsonFiles/check.json';
import sendKite from '../assets/jsonFiles/sendKite.json';

interface IModal {
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Modal = ({ isModalOpen = false, setIsModalOpen }: IModal) => {
	const form = useRef<HTMLFormElement>(null);
	const [isSending, setIsSending] = useState(false);
	const [showForm, setShowForm] = useState(true);

	const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setShowForm(false);
		setIsSending(true);

		if (form.current)
			emailjs
				.sendForm(
					process.env.REACT_APP_EMAIL_SERVICE_ID as string,
					process.env.REACT_APP_EMAIL_TEMPLATE_ID as string,
					form.current,
					{
						publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
					}
				)
				.then(
					() => {
						setIsSending(false);
					},
					(error) => {
						console.log('FAILED...', error.text);
					}
				);
	};

	if (!isModalOpen) return <></>;

	return (
		<ModalWrapper>
			<ModalCloseButton>
				<FaRegTimesCircle onClick={() => setIsModalOpen(false)} />
			</ModalCloseButton>
			<ModalCard>
				<h4>Contact Us</h4>
				<ImageWrapper>
					<img
						className="projectmodal_modalImage__EWd63"
						src={contact}
						alt="Contact Form"
					/>
				</ImageWrapper>
				<ModalContent>
					{showForm ? (
						<form className="w-full py-2" ref={form} onSubmit={sendEmail}>
							<ServiceWrapper>
								<ModalInput name="from_name" type="text" placeholder="Name" />
								<ModalInput name="user_email" type="text" placeholder="Email" />
								<ModalInput name="user_phone" type="text" placeholder="Phone" />
								<ModalInput
									name="user_subject"
									type="text"
									placeholder="Subject"
								/>
								<ModalTextArea name="message" placeholder="Message" />
								<OutlineButton
									type="submit"
									style={{ margin: '1rem', textAlign: 'center' }}
								>
									Submit
								</OutlineButton>
							</ServiceWrapper>
						</form>
					) : (
						!isSending && <Lottie loop={false} animationData={check} play />
					)}
					{isSending && (
						<Lottie loop={isSending} animationData={sendKite} play />
					)}
				</ModalContent>
			</ModalCard>
		</ModalWrapper>
	);
};
