import { useEffect, useState } from 'react';
import { PopupModal } from 'react-calendly';
import { Reveal } from './Reveal';
import { HeaderLink, HeaderWrapper, OutlineButton } from './styled/style';
import { darkColors, lightColors } from '../utils/constant';

interface IHeader {
	isDark?: boolean;
}

const Header = ({ isDark = true }: IHeader) => {
	const [isOpen, setIsOpen] = useState(false);
	const [colors, setColors] = useState(darkColors);

	useEffect(() => {
		setColors(!isDark ? darkColors : lightColors);
	}, [isDark]);

	return (
		<HeaderWrapper>
			<HeaderLink>
				<Reveal main="upToDownMain" showGreenAnimation={false} delay={0.2}>
					<h1>Code Commit</h1>
				</Reveal>
				{/* <Reveal main="upToDownMain" showGreenAnimation={false} delay={0.2}>
					<span>
						<a
							target="_blank"
							rel="nofollow noreferrer"
							href="https://www.linkedin.com"
						>
							<FaFacebookSquare size={25} />
						</a>
					</span>
				</Reveal>
				<Reveal main="upToDownMain" showGreenAnimation={false} delay={0.3}>
					<span>
						<a
							target="_blank"
							rel="nofollow noreferrer"
							href="https://www.github.com"
						>
							<FaInstagram size={25} />
						</a>
					</span>
				</Reveal>
				<Reveal main="upToDownMain" showGreenAnimation={false} delay={0.4}>
					<span>
						<a
							target="_blank"
							rel="nofollow noreferrer"
							href="https://www.twitter.com"
						>
							<FaTwitterSquare size={25} />
						</a>
					</span>
				</Reveal>
				<Reveal main="upToDownMain" showGreenAnimation={false} delay={0.5}>
					<span>
						<a
							target="_blank"
							rel="nofollow noreferrer"
							href="https://www.codepen.io"
						>
							<FaGithubSquare size={25} />
						</a>
					</span>
				</Reveal> */}
			</HeaderLink>
			<div className="flex">
				<Reveal main="rightToLeftMain" slide="rightToLeftSlide">
					<div className="right-div-2">
						<OutlineButton onClick={() => setIsOpen(true)}>
							Book an appointment!
						</OutlineButton>
					</div>
				</Reveal>
				<PopupModal
					url={`https://calendly.com/code-commit-info`}
					rootElement={document.getElementById('root') as HTMLElement}
					onModalClose={() => setIsOpen(false)}
					open={isOpen}
					pageSettings={{
						primaryColor: colors.brand,
						textColor: colors.text,
						backgroundColor: colors.background,
					}}
				/>
			</div>
		</HeaderWrapper>
	);
};

export default Header;
