import {
	FaArrowRight,
	FaLinkedin,
} from 'react-icons/fa';
import { Reveal } from './Reveal';
import {
	Header,
	HeaderLine,
	HeaderTitle,
	AboutLinks,
	AboutWrapper,
	Section,
	StatsWrapper,
} from './styled/style';

const About = () => {
	return (
		<Section id="about">
			<Header>
				<HeaderLine></HeaderLine>
				<h3>
					<HeaderTitle>
						About<span>.</span>
					</HeaderTitle>
				</h3>
			</Header>
			<AboutWrapper>
				<div>
					<Reveal>
						<p className="highlightFirstLetter">
							Hey! We're Code Commit Inc, if you haven't already gathered that
							by now. We are a team consisting of people who are experts in their domains. We deliver outstanding results by 
							blending innovative ideas with our wide experience.
						</p>
					</Reveal>
					<Reveal>
						<p>
							We provide a wide range of digital solutions. Our winning solutions and experiences help many of our clients interact and 
							engage with their customers in the best possible way.
						</p>
					</Reveal>
					<Reveal>
						<p>
							Our experts provide website design and content creation services to business owners. Your business deserves great representation 
							online, and our team of creatives are here to help to design a website that looks great and shows up online when people are 
							searching for what you do.
						</p>
					</Reveal>
					<Reveal>
						<p>
							Let's connect and get started to make your presence online.
						</p>
					</Reveal>
					<Reveal>
						<AboutLinks>
							<div className="linksText">
								<span>My links</span>
								<FaArrowRight />
							</div>
							<div className="links">
								<span>
									<a
										target="_blank"
										rel="nofollow noreferrer"
										href="https://www.linkedin.com/company/code-commit"
									>
										<FaLinkedin size={25} />
									</a>
								</span>
							</div>
						</AboutLinks>
					</Reveal>
				</div>
				<StatsWrapper>
					<Reveal slide={'rightToLeftSlide'}>
						<div>
							<h4>
								<svg
									stroke="currentColor"
									fill="currentColor"
									stroke-width="0"
									viewBox="0 0 1024 1024"
									color="var(--brand)"
									style={{ color: 'var(--brand)' }}
									height="2.4rem"
									width="2.4rem"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM513.1 518.1l-192 161c-5.2 4.4-13.1.7-13.1-6.1v-62.7c0-2.3 1.1-4.6 2.9-6.1L420.7 512l-109.8-92.2a7.63 7.63 0 0 1-2.9-6.1V351c0-6.8 7.9-10.5 13.1-6.1l192 160.9c3.9 3.2 3.9 9.1 0 12.3zM716 673c0 4.4-3.4 8-7.5 8h-185c-4.1 0-7.5-3.6-7.5-8v-48c0-4.4 3.4-8 7.5-8h185c4.1 0 7.5 3.6 7.5 8v48z"></path>
								</svg>
								<span>Use at work</span>
							</h4>
							<div className="statGrid">
								<span className="chip">JavaScript</span>
								<span className="chip">TypeScript</span>
								<span className="chip">HTML</span>
								<span className="chip">CSS</span>
								<span className="chip">React</span>
								<span className="chip">Redux</span>
								<span className="chip">NodeJS</span>
								<span className="chip">Express</span>
								<span className="chip">Postgres</span>
								<span className="chip">MongoDB</span>
								<span className="chip">GitHub</span>
								<span className="chip">Jira</span>
								<span className="chip">Heroku</span>
								<span className="chip">AWS</span>
							</div>
						</div>
					</Reveal>
					<Reveal slide={'rightToLeftSlide'}>
						<div className="statColumn">
							<h4>
								<svg
									stroke="currentColor"
									fill="currentColor"
									stroke-width="0"
									viewBox="0 0 1024 1024"
									color="var(--brand)"
									style={{ color: 'var(--brand)' }}
									height="2.4rem"
									width="2.4rem"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zM288 421a48.01 48.01 0 0 1 96 0 48.01 48.01 0 0 1-96 0zm224 272c-85.5 0-155.6-67.3-160-151.6a8 8 0 0 1 8-8.4h48.1c4.2 0 7.8 3.2 8.1 7.4C420 589.9 461.5 629 512 629s92.1-39.1 95.8-88.6c.3-4.2 3.9-7.4 8.1-7.4H664a8 8 0 0 1 8 8.4C667.6 625.7 597.5 693 512 693zm176-224a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z"></path>
								</svg>
								<span>Use for fun</span>
							</h4>
							<div className="statGrid">
								<span className="chip">Rust</span>
								<span className="chip">Tailwind</span>
								<span className="chip">Java</span>
								<span className="chip">Spring</span>
								<span className="chip">Figma</span>
								<span className="chip">Whimsical</span>
								<span className="chip">Planetscale</span>
								<span className="chip">GraphQL</span>
								<span className="chip">Python</span>
								<span className="chip">FastAPI</span>
							</div>
						</div>
					</Reveal>
				</StatsWrapper>
			</AboutWrapper>
		</Section>
	);
};

export default About;
