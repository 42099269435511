export const darkColors = {
	background: '#111',
	'bg-opaque': 'hsla(0, 0%, 7%, 0.25)',
	'background-light': '#232323',
	'background-dark': '#080808',
	'service-background': '#fff',
	text: '#ebecf3',
	brand: '#6c63ff',
};

export const lightColors = {
	background: '#ebecf3',
	'bg-opaque': 'hsl(0deg 0% 100% / 25%)',
	'background-light': '#ebecf3',
	'background-dark': '#ebecf3',
	'service-background': '#111',
	text: '#080808',
	brand: '#6c63ff',
};

export const particle = {
	dark: {
		bg: '#111',
		particleColor: '#fff',
		linkColor: '#6c63ff',
	},
	light: {
		bg: '#fff',
		particleColor: '#111',
		linkColor: '#6c63ff',
	},
};

export const projects = [
	{
		id: 'solution',
		name: 'Workflow Solution',
		techStack: '.Net - React - Angular - Firebase - Node',
		decription: `Get your process, case or project workflow automated to increase productivity and mitigate manual errors.`,
	},
	{
		id: 'seo',
		name: 'Search Engine Optimization',
		decription: `Improve the quality and quantity of your website with organic traffic to potentially convert them into customers.`,
	},
	{
		id: 'website',
		name: 'Web Development',
		techStack: 'React - Typescript - Netlify - Firebase',
		decription: `Build your online presence with a budget and user friendly website to reach wider demographic range fulfilling the consumer needs.`,
	},
	{
		id: 'mentor',
		name: 'Mentorship',
		techStack: 'Full Stack',
		decription: ` Learn the in-demand coding skills from an experienced mentor to leverage your interest and build a career as a programmer.`,
	},
	{
		id: 'email',
		name: 'Business Email Domain',
		decription: `Get your business email and website domain setup to grab your perfect name online adding credibility to your brand name.`,
	},
];

export const variant = {
	leftToRightSlide: { hidden: { left: 0 }, visible: { left: '100%' } },
	rightToLeftSlide: { hidden: { right: 0 }, visible: { right: '100%' } },
	downToUpMain: {
		hidden: { opacity: 0, translateY: 90 },
		visible: { opacity: 1, translateY: 0 },
	},
	upToDownMain: {
		hidden: { opacity: 0, translateY: -90 },
		visible: { opacity: 1, translateY: 0 },
	},
	leftToRightMain: {
		hidden: { opacity: 0, translateX: -30 },
		visible: { opacity: 1, translateX: 0 },
	},
	rightToLeftMain: {
		hidden: { opacity: 0, translateX: 30 },
		visible: { opacity: 1, translateX: 0 },
	},
};

export const navigation = [
	{ id: 'about', display: 'About', delay: 0.3 },
	{ id: 'service', display: 'Services', delay: 0.4 },
	{ id: 'stack', display: 'Tech Stack', delay: 0.5 },
	{ id: 'contact', display: 'Contact', delay: 0.6 },
];

export const info = {
	email: 'info@code-commit.com',
	primaryPhoneDisplay: '+1 (249) 989-2949',
	primaryPhoneCall: '+1249989249',
	secondaryPhoneDisplay: '+1-(548) 333-2949',
	secondaryPhoneCall: '+15483332949',
};
