import { FaMailBulk, FaPhone } from 'react-icons/fa';
import { Reveal } from './Reveal';
import {
	ContactLinkedIn,
	ContactTitle,
	ContactWrapper,
	Section,
} from './styled/style';
import { info } from '../utils/constant';

const Contact = () => {
	return (
		<Section id="contact">
			<ContactWrapper>
				<Reveal width="100%">
					<ContactTitle>
						Contact<span>.</span>
					</ContactTitle>
				</Reveal>
				<Reveal width="100%">
					<ContactLinkedIn>
						Shoot us an email if you want to connect! You can also find us on{' '}
						<a
							target="_blank"
							rel="nofollow noreferrer"
							href="https://www.linkedin.com/company/code-commit"
						>
							Linkedin{'        '}
						</a>
					</ContactLinkedIn>
				</Reveal>
				<Reveal width="100%">
					<a href={`mailto:${info.email}`}>
						<div className="contactInfo">
							<FaMailBulk />
							<span>{info.email}</span>
						</div>
					</a>
					<a href={`tel:${info.primaryPhoneCall}`}>
						<div className="contactInfo">
							<FaPhone />
							<span>{info.primaryPhoneDisplay}</span>
						</div>
					</a>
					<a href={`tel:${info.secondaryPhoneCall}`}>
						<div className="contactInfo">
							<FaPhone />
							<span>{info.secondaryPhoneDisplay}</span>
						</div>
					</a>
				</Reveal>
			</ContactWrapper>
		</Section>
	);
};

export default Contact;
