import { useEffect, useMemo, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import {
	type ISourceOptions,
	MoveDirection,
	OutMode,
} from '@tsparticles/engine';
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from '@tsparticles/slim'; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.
import './App.css';
import Layout from './components/Layout';
import { particle } from './utils/constant';

const App = () => {
	const [init, setInit] = useState(false);
	const [colors, setColors] = useState(particle.dark);

	// this should be run only once per application lifetime
	useEffect(() => {
		initParticlesEngine(async (engine) => {
			await loadSlim(engine);
		}).then(() => {
			setInit(true);
		});
	}, []);

	const updateOptions = (checked: boolean) => {
		setColors(checked ? particle.light : particle.dark);
	};

	const options: ISourceOptions = useMemo(
		() => ({
			background: {
				color: {
					value: colors.bg,
				},
			},
			fpsLimit: 120,
			interactivity: {
				events: {
					onClick: {
						enable: true,
						mode: 'push',
					},
					onHover: {
						enable: false,
						mode: 'repulse',
					},
				},
				modes: {
					push: {
						quantity: 1,
					},
					repulse: {
						distance: 200,
						duration: 0.4,
					},
				},
			},
			particles: {
				color: {
					value: colors.particleColor,
				},
				links: {
					color: colors.linkColor,
					distance: 100,
					enable: true,
					opacity: 0.4,
					width: 1,
				},
				move: {
					direction: MoveDirection.none,
					enable: true,
					outModes: {
						default: OutMode.bounce,
					},
					random: false,
					speed: 0.2,
					straight: false,
				},
				number: {
					density: {
						enable: true,
					},
					value: 130,
				},
				opacity: {
					value: 0.5,
				},
				shape: {
					type: 'circle',
				},
				size: {
					value: { min: 1, max: 1.5 },
				},
			},
			detectRetina: true,
		}),
		[colors]
	);

	if (init) {
		return (
			<>
				<Particles
					id="tsparticles"
					className="fixed w-full h-full top-0 left-0 -z-0"
					options={options}
				/>
				<Layout updateOptions={updateOptions} />
			</>
		);
	}

	return <></>;
};

export default App;
