import { useState } from 'react';
import About from './About';
import Contact from './Contact';
import Header from './Header';
import Hero from './Hero';
import Navbar from './Navbar';
import TechStack from './TechStack';
import { HomeContainer } from './styled/style';
import { Modal } from './Modal';
import Service from './Service';

interface ILayout {
	updateOptions: (checked: boolean) => void;
}

const Layout = ({ updateOptions }: ILayout) => {
	const [isDark, setIsDark] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const themeUpdated = (checked: boolean) => {
		updateOptions(checked);
		setIsDark(!checked);
	};

	return (
		<HomeContainer>
			<Navbar updateOptions={themeUpdated} isDark={isDark} />
			<main>
				<Header isDark={isDark} />
				<Hero setIsModalOpen={setIsModalOpen} />
				<About />
				<Service />
				<TechStack />
				<Contact />
				<Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
				<div
					style={{
						height: '200px',
						background:
							'linear-gradient(180deg, var(--background), var(--background-dark))',
					}}
				/>
			</main>
		</HomeContainer>
	);
};

export default Layout;
